<template>
    <v-card class="pa-5">
        <Dialog :dialog="success" :msg="'Your inquiry has been sent!'" @close="success = false"/>
        <span class="f20 fw600">Hi there! How can we help?</span>
        <v-divider class="l-primary my-5" width="70%" />
        <v-form ref="form" :class="$vuetify.breakpoint.mobile ? 'pa-5': ''">
            <v-text-field 
                placeholder="Full Name"
                type="text"
                outlined
                dense
                hide-details="auto"
                class="general-custom-field my-3 roboto f14 fw500 secondary-2--text"
                v-model="form.name"
                :rules="[v => !!v || 'This is required']"
                :error-messages="errs && errs.name"
            />
            <v-text-field outlined dense placeholder="Email Address" 
                type="email"
                class="login-custom-field my-3 f14 roboto fw500 secondary-1--text" 
                hide-details="auto"
                color="l-primary"
                append-icon="mdi-email-outline"
                v-model="form.email"
                :rules="[v => !!v || 'This is required']"
                :error-messages="errs && errs.email"
            />
            <v-text-field 
                placeholder="Subject"
                type="text"
                outlined
                dense
                v-model="form.subject"
                class="general-custom-field my-3 roboto f14 fw500 secondary-2--text"
                required
                hide-details="auto"
                :rules="[v => !!v || 'This is required']"
                :error-messages="errs && errs.subject"
            />
            <v-text-field 
                placeholder="Message"
                type="text"
                outlined
                dense
                v-model="form.message"
                class="general-custom-field my-3 roboto f14 fw500 secondary-2--text"
                required
                hide-details="auto"
                :rules="[v => !!v || 'This is required']"
                :error-messages="errs && errs.message"
            />    
            <v-btn 
                large 
                block 
                :loading="loading"
                color="l-primary white--text" 
                style="border-radius: 10px" 
                @click="send">
                SUBMIT
            </v-btn>
        </v-form>
    </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import Dialog from '@/components/landing/common/dialog.vue'
export default {
    name: 'ContactView',
    components: {
        Dialog
    },
    data: () => ({
        form: {
            name: null,
            email: null,
            subject: null,
            message: null,
        },
        loading: false,
        success: false,
        errs: [],
    }),
    methods: {
        ...mapActions(['sendContactUs']),

        send(){
            if(this.$refs.form.validate()){
                this.loading = true
                let _form = new FormData()

                _form.append("name", this.form.name)
                _form.append("email", this.form.email)
                _form.append("subject", this.form.subject)
                _form.append("message", this.form.message)
                this.sendContactUs(_form).then(() => {
                    this.loading = false
                    this.success = true
                    this.$refs.form.reset()
                }).catch(e => {
                    this.errs = e
                    this.loading = false
                    this.success = false
                })
            }
        }
    }
}
</script>